import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Luxury Drive Rentals
			</title>
			<meta name={"description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:title"} content={"Luxury Drive Rentals"} />
			<meta property={"og:description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link4" />
			<Override slot="link5" />
			<Override slot="text1" />
			<Override slot="text" />
		</Components.Header>
		<Section padding="80px 0 80px 0" background="--color-lightD1" sm-padding="50px 0 50px 0" quarkly-title="Content-13">
			<Override slot="SectionContent" width="900px" lg-width="95%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				background="#ffffff"
				border-radius="25px"
				margin="0px 0px 25px 0px"
				sm-flex-direction="column"
				align-self="center"
				align-items="center"
				align-content="center"
			>
				<Box
					min-width="100px"
					min-height="auto"
					padding="40px 40px 40px 40px"
					flex-direction="column"
					width="50%"
					sm-padding="20px 20px 20px 20px"
					sm-width="100%"
					align-self="center"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Приєднайтеся до Luxury Drive Experience
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 22px/1.5 --fontFamily-sans" color="#5f666e">
						Вирушайте в подорож, як ніхто інший, з Luxury Drive Rentals. Будь то особливий випадок, відрядження чи відпочинок на вихідних, у нас є ідеальний автомобіль, який відповідатиме вашим потребам і бажанням. Наше прагнення до досконалості та пристрасть до розкішного водіння відрізняє нас від конкурентів.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"
					display="block"
					width="50%"
					border-radius="25px"
					height="100%"
					object-fit="cover"
					sm-width="100%"
					max-height="300px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10%3A17%3A01.519Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10%3A17%3A01.519Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10%3A17%3A01.519Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10%3A17%3A01.519Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10%3A17%3A01.519Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10%3A17%3A01.519Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10%3A17%3A01.519Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box md-margin="0px 0px 35px 0px">
					<Text
						margin="0px 0px 20px 0px"
						font="--headline3"
						padding="0px 0px 0px 25px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="--color-secondary"
					>
						Відчуйте подорож на все життя
					</Text>
				</Box>
				<Box padding="0px 0px 0px 80px" md-padding="0px 0px 0px 25px" display="flex" flex-direction="column">
					<Link
						href="#"
						color="#000000"
						font="normal 500 20px/1.5 --fontFamily-sans"
						text-decoration-line="initial"
						margin="0px 0px 8px 0px"
						display="block"
					>
						+60126035806
					</Link>
					<Link
						href="#"
						color="#000000"
						font="normal 500 20px/1.5 --fontFamily-sans"
						text-decoration-line="initial"
						margin="0px 0px 8px 0px"
						display="block"
					>
						Kharkivs'ke Hwy,{" "}
						<br />
						165, Kyiv, 02000
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});